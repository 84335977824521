const Footer = () => {
    return (
        <footer class="text-bg-dark text-center text-lg-start">
            <div class="text-center p-3" style={{"background-color": "rgba(0, 0, 0, 0.2)"}}>
                Developed by <strong class="footer-theme">Ryan Bell</strong>
            </div>
            </footer>
    )
  };
  
export default Footer;