import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileLines, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const Header = () => {
  const renderResumeTooltip = props => (
    <Tooltip {...props}>Download my Resume!</Tooltip>
  );
  const renderEmailTooltip = props => (
    <Tooltip {...props}>Send me an email!</Tooltip>
  );
  const renderLinkedInTooltip = props => (
    <Tooltip {...props}>Check out my LinkedIn!</Tooltip>
  );

  return (
      <nav class="navbar navbar-expand-lg text-bg-dark navbar-dark fixed-top hero-bg">
        <div class="container-fluid">
          <a class="navbar-brand" style={{"font-size": "1.5rem", "padding-top": "0px"}} href="/">&gt;_</a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <a class="nav-link" aria-current="page" href="#aboutme"><small>echo</small> WHOAMI</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#experience"><small>echo</small> $EXPERIENCE</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#skills"><small>echo</small> $SKILLS</a>
              </li>
            </ul>
            <form class="d-flex icon-white">
              <ul class="navbar-nav">
              <li class="nav-item d-flex">
                  <OverlayTrigger placement="bottom" overlay={renderEmailTooltip}>
                    <a class="nav-link d-flex align-items-center " href="mailto:ryanbellse@gmail.com" target="_blank" rel="noreferrer" >
                      <FontAwesomeIcon icon={faEnvelope} style={{"color": "#b878eb"}}/>
                      <small class="quick-links-text ps-2">Contact Me</small>
                    </a>
                  </OverlayTrigger>
                </li>
                <li class="nav-item">
                  <OverlayTrigger placement="bottom" overlay={renderLinkedInTooltip}>
                    <a class="nav-link d-flex align-items-center" href="https://www.linkedin.com/in/ryanbellcu/" target="_blank" rel="noreferrer" >
                      <FontAwesomeIcon icon={faLinkedin} style={{"color": "#b878eb"}}/>
                      <small class="quick-links-text ps-2">LinkedIn</small>
                    </a>
                  </OverlayTrigger>
                </li>
                <li class="nav-item">
                  <OverlayTrigger placement="bottom" overlay={renderResumeTooltip}>
                    <a class="nav-link d-flex align-items-center" href="http://ryanbellse.com/ryan-bell-resume.pdf" target="_blank" rel="noreferrer" >
                      <FontAwesomeIcon icon={faFileLines} style={{"color": "#b878eb"}}/>
                      <small class="quick-links-text ps-2">Resume</small>
                    </a>
                  </OverlayTrigger>
                </li>
              </ul>
            </form>
          </div>
        </div>
      </nav>
  )
};


export default Header;