import React from 'react';
import ReactDOM from 'react-dom/client';
import './custom.scss';
import './index.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./pages/Header";
import Home from "./pages/Home";
import Footer from "./pages/Footer";
import NoPageFound from './pages/NoPageFound';

export default function App() {
  return (
    <Router>
      <header>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/gh/devicons/devicon@v2.15.1/devicon.min.css"/>
      </header>
      <Header />
      <div>
      <Routes>
        <Route index element={<Home />} />
        <Route path="*" element={<NoPageFound />} />
      </Routes>
      </div>
      <Footer />
    </Router>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

