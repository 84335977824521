

const Home = () => {
    return (
      <div class="container-fluid p-0 pt-3">


        <div id="aboutme" class="container-fluid text-bg-dark hero-bg bg-image"  style={{"padding": "4rem"}}>
          <div class="container">
            <div class="row align-items-center">
              <div class="col-sm-12 col-md-5">
                <img class="mx-auto d-block programmer-img" src={process.env.PUBLIC_URL + '/programmer.png'} alt="Profile"/>
              </div>
              <div class="col-sm-12 col-md-7">
                <div class="d-flex">
                  <h6 class="intro-text p-3">Hi, my name is <strong class="footer-theme">Ryan Bell</strong></h6>
                </div>
                <h1>About Me</h1>
                <h6>I am a <strong class="footer-theme">Software Engineer</strong>. I graduated from <strong class="footer-theme">Clarkson University</strong> with a <strong class="footer-theme">B.S in Software Engineering</strong> in 2021. My passion for programming and solving complex software challenges grows everyday. I am looking to become a <strong class="footer-theme">vital part of a team</strong> creating solutions for modern day problems while pioneering innovative technology <strong class="footer-theme">for the future</strong>.</h6>
                <hr class="border-2 border-top border-secondary"/>
              </div>
            </div>
          </div>
        </div>


        <div id="experience" class="container-fluid text-bg-dark bg-corner-dark"  style={{"padding": "2rem"}}>
          <div class="container">
            <div class="row">
                <h1>Professional Experience</h1>
            </div>
            <hr class="border-2 border-top border-secondary"/>
            <div class="row">
              <div class="col">
                <h3 class="experience-title"><strong class="footer-theme">Software Engineer Consultant</strong></h3>
              </div>
              <div class="col" style={{"text-align": "end"}}>
                <span class="experience-subtitle"><strong class="footer-theme">TCS | Contracted by The Boeing Company</strong></span>
              </div>
            </div>
            <div class="row">
                  <span>- Delivered scalable, front-end architecture, back-end applications, and UX enhancements to a complex web application utilizing Java, CSS, HTML, and JavaScript, within an Agile environment</span>
                  <span>- Managed three key employee-facing applications by providing end-to-end support, such as end-user tickets, error troubleshooting, certificate changes, server management, code fixes as needed, and more</span>
            </div>
            <div class="row" style={{"text-align": "center"}}>
              <span class="experience-subtitle"><strong>Key Achievements</strong></span>
            </div>
            <div class="row">
              <div class="col-sm-12 col-lg-4 p-2">
                <div class="card shadow mx-auto d-block text-light project-bg" style={{"height": "175px"}}>
                  <div class="card-body">
                    <p class="card-text text-bottom"> Designed and developed a secure tagging and attestation system for all content posted
                      on a web application to comply with Boeing legal regulations and prevent confidential
                      information leaks
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-lg-4 p-2">
                <div class="card shadow mx-auto d-block text-light project-bg" style={{"height": "175px"}}>
                  <div class="card-body">
                    <p class="card-text text-bottom">Modernized the front-end architecture and user interface by upgrading to newer
                      technologies and creating responsive, user-friendly web pages and navigation
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-lg-4 p-2">
                <div class="card shadow mx-auto d-block text-light project-bg" style={{"height": "175px"}}>
                  <div class="card-body">
                    <p class="card-text text-bottom">Decreased the amount of user tickets for a specific application by ~50% by completely 
                      redesigning the UI of its forms with extra focus on the UX to prevent user errors, also streamlining the process and saving time for the end users
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <hr class="border-2 border-top border-secondary"/>
            <div class="row">
              <div class="col">
                <h3 class="experience-title"><strong class="footer-theme">Web Developer</strong></h3>
              </div>
              <div class="col" style={{"text-align": "end"}}>
                <span class="experience-subtitle"><strong class="footer-theme">Clarkson University | On-Campus Student Job</strong></span>
              </div>
            </div>
            <div class="row">
                  <span>- Learned and applied valuable web design principles to Clarkson’s website</span>
            </div>
            <hr class="border-2 border-top border-secondary"/>
            <hr class="border-2 border-top border-secondary"/>

            <div class="row">
                <h1>Personal Experience</h1>
            </div>
            <hr class="border-2 border-top border-secondary"/>
            <div class="row">
              <h3 class="experience-title"><strong class="footer-theme">Java Game Developer</strong></h3>
            </div>
            <div class="row">
                  <span>- Designed, developed, and implemented countless video game features, each having their own challenges to overcome</span>
                  <span>- Researched and learned valuable core skills in Java OOP and SQL that have been utilized in a professional environment</span>
            </div>
            <hr class="border-2 border-top border-secondary"/>
            {/*
            <div class="row">
              <div class="col-sm-6 col-lg-3 p-3">
                <div class="card shadow mx-auto d-block text-light project-bg" style={{"height": "250px"}}>
                  <div class="card-body">
                    <h2 class="card-title">inSite</h2>
                    <footer class="blockquote-footer pt-1">2021-2023</footer>
                    <p class="card-text text-bottom">Java web application utilizing Gradle, Spring, Oracle DB, SOLR and more.</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-lg-3 p-3">
                <div class="card shadow mx-auto d-block text-light project-bg" style={{"height": "250px"}}>
                  <div class="card-body">
                    <h2 class="card-title">IDAP</h2>
                    <footer class="blockquote-footer pt-1">2021-2023</footer>
                    <p class="card-text text-bottom">Web application built with Angular frontend with Java backend. Utilizes Gradle and a suite of APIs (IDWS) for backend calls.</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-lg-3 p-3">
                <div class="card shadow mx-auto d-block text-light project-bg" style={{"height": "250px"}}>
                  <div class="card-body">
                    <h2 class="card-title">IDWS</h2>
                    <footer class="blockquote-footer pt-1">2021-2023</footer>
                    <p class="card-text text-bottom">Suite of 8 REST APIs built with Java and Spring. Utilizes Gradle, LDAP protocol, and Oracle DB.</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-lg-3 p-3">
                <div class="card shadow mx-auto d-block text-light project-bg" style={{"height": "250px"}}>
                  <div class="card-body h-100">
                    <h2 class="card-title">Java Game</h2>
                    <footer class="blockquote-footer pt-1">2015-2020</footer>
                    <p class="card-text">Java OOP personal project. Environment to learn valuable core Java skills.</p>
                  </div>
                </div>
              </div>
            </div>
            */}
          </div>
        </div>

        <div id="skills" class="container-fluid text-bg-dark" style={{"padding": "2rem"}}>
          <div class="container">
            <div class="row text-center">
              <div class="col-7">
                <div class="row">
                  <div class="col devicon-col">
                    <div class="mx-auto d-block">
                    <i class="devicon devicon-java-plain devicon-purple"></i>
                    <h4 class="devicon-text footer-theme">Java</h4>
                    </div>
                  </div>
                  <div class="col devicon-col">
                    <div class="mx-auto d-block">
                    <i class="devicon devicon-gradle-plain devicon-purple"></i>
                    <h4 class="devicon-text footer-theme">Gradle</h4>
                    </div>
                  </div>
                  <div class="col devicon-col">
                    <div class="mx-auto d-block">
                    <i class="devicon devicon-spring-plain devicon-purple"></i>
                    <h4 class="devicon-text footer-theme">Spring</h4>
                    </div>
                  </div>
                  <div class="col devicon-col">
                    <div class="mx-auto d-block">
                    <i class="devicon devicon-angularjs-plain devicon-white"></i>
                    <h4 class="devicon-text">AngularJS</h4>
                    </div>
                  </div>
                  <div class="col devicon-col">
                    <div class="mx-auto d-block">
                    <i class="devicon devicon-react-original devicon-white"></i>
                    <h4 class="devicon-text">React</h4>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col devicon-col">
                    <div class="mx-auto d-block">
                    <i class="devicon devicon-oracle-original devicon-purple"></i>
                    <h4 class="devicon-text footer-theme">Oracle</h4>
                    </div>
                  </div>
                  <div class="col devicon-col">
                    <div class="mx-auto d-block">
                    <i class="devicon devicon-microsoftsqlserver-plain devicon-purple"></i>
                    <h4 class="devicon-text footer-theme">SQL</h4>
                    </div>
                  </div>
                  <div class="col devicon-col">
                    <div class="mx-auto d-block">
                    <i class="devicon devicon-javascript-plain devicon-white"></i>
                    <h4 class="devicon-text">JavaScript</h4>
                    </div>
                  </div>
                  <div class="col devicon-col">
                    <div class="mx-auto d-block">
                    <i class="devicon devicon-html5-plain devicon-white"></i>
                    <h4 class="devicon-text">HTML</h4>
                    </div>
                  </div>
                  <div class="col devicon-col">
                    <div class="mx-auto d-block">
                    <i class="devicon devicon-css3-plain devicon-white"></i>
                    <h4 class="devicon-text">CSS</h4>
                    </div>
                  </div>

                </div>
              </div>
              <div class="col-5 text-center my-auto">
                <div class="container px-4">
                    <h1>Technical Skills</h1>
                    <h6 class="lead">These are some of the technical skills I <strong class="footer-theme">actively use</strong> in my <strong class="footer-theme">personal projects</strong> and in my <strong class="footer-theme">professional career</strong>. I am eager to expand my skillset and <strong class="footer-theme">learn new things</strong> every day.</h6>
                </div>
                <br/>
                <div class="container px-4">
                    <h6 class="lead"><b>Non-Technical Skills</b> - Engineering mindset, Excellent troubleshooter, Lead by example, Desire to learn</h6>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*
        <div id="skills" class="container-fluid text-bg-dark" style={{"padding": "2rem"}}>
          <div class="container">
            <div class="row">
              <div class="container text-center">
                <h3>Career Progress</h3>
                <div class="progress">
                  <div class="progress-bar progress-bar-striped progress-bar-animated text-light" style={{"width": "40%", "background-color": "#460a77"}}><strong>2 Years</strong></div>
                </div>
                <small class="float-start">Entry</small>
                <small>Junior</small>
                <small class="float-end">Senior</small>
              </div>
            </div>
          </div>
        </div>
        */}

      </div>
    )
  };
  
export default Home;